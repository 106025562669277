import request from "@/api/request";
import baseURL from "../index";
import * as apiCode from "../apiCode";
// import Qs from 'qs'

/// 获取爆料分类列表
export function fetchCategorys(data) {
  return request({
    url: baseURL + apiCode.getBaoliaoCategory,
    data: data
  });
}
/// 获取爆料分类列表
export function fetchList(data) {
  return request({
    url: baseURL + apiCode.getBaoliaoList,

    // 传params或用Qs.stringify转换后，可以以queryString形式发送
    // data则以payload形式发送
    // data: Qs.stringify(data),
    params: data 
  });
}
