<template>
  <AppPage :name="'home'">
    <div class="main">
      
      <!-- 一个白色块 -->
      <div style="height: 230px;background-color:rgba(0,0,0,0)" class="full-width mb-20">
        <UserRead></UserRead>
      </div>

      <div class="flex flex-main-between">
        <!-- 限时秒杀 -->
        <!-- <div style="width: 375px;height: 273px; overflow: hidden;" class=""> -->
          <!-- <iframe style="width: 375px; height: 279px;"
                src="https://api.meiwulist.com/api_service/api/v1/meiwu/miaoshaH5Mokuai2" scrolling="false"
                frameborder="0"></iframe> -->
          <!-- <Xianshimiaosha /> -->
        <!--</div> -->
        <!-- 2小时最热榜 -->
        <!-- <div>
          <HotList2H />
        </div> -->
      </div>

      <div class="content">
        
        <div class="category-list flex bg-white pl-25 pr-25" >
          <div v-for="(item, i) in categorys" v-bind:key="i" class="category-name ml-15 mr-15" :class="item == activeCategory.name ? 'active' : ''" @click="onCategoryNameClick(item)">{{item}}</div>
        </div>
        <div style="width: 100%; height: 1px; background: #f8f8f8;"></div>
        <div>
            <div v-if="tabelshow">
              <div class="item-list full-width box-border" style="overflow: auto;" >
              <div v-for="(e,i) in list" :key="i">
                <ListItem :item="e" :category="listParams['category']"/>
                <div class="pl-25 pr-25">
                  <div style="width: 100%; height: 1px; background-color: #f8f8f8"></div>
                </div>
              </div>
            </div>
            </div>
            <div v-else>
               <Loading/>
            </div>
        </div>
       
      </div>

    </div>
    <div class="ml-20"></div>
    <div class="side">
      
      <AppQRCode />
      <VideoIntro />
      <AppComment />
      <ContactUs />
      <Copyright />
    </div>
  </AppPage>
</template>
<script>
import {fetchCategorys,fetchList} from '@/api/modules/baoliao'
import ListItem from './components/ListItem.vue'
import UserRead from './components/NewUserRead.vue'
import Loading from './components/Loading/Loading.vue'
// import HotList2H from './components/HotList2H.vue'
// import Xianshimiaosha from './components/Xianshimiaosha.vue'
export default {
  name: "Home",
  components: {ListItem,UserRead,Loading},
  data() {
     return {
       loading: false,
       activeCategory: {
         name: "最热"
       },
       categorys: [],
       isLastPage: 0,
       listParams: {
         pageNo: 1,
         pageSize: 10,
         userId: 5,
         tagId: 199,
       },
       list: [],
       tabelshow:false,
     }
  },
  created(){
    fetchCategorys().then((res) => {
      this.categorys = res.data

      this.listParams['category'] = this.activeCategory.name
      this.queryData()
    })
  },
  computed:{
  },
  methods:{
    onCategoryNameClick(name){
      this.tabelshow = false;
      this.activeCategory.name = name
      this.activeCategoryClassName
      this.listParams['category'] = this.activeCategory.name
      this.list = []
      this.listParams.pageNo = 1
      this.queryData()
    },
    queryData() {
      var self = this

      this.loading = true

      fetchList(this.listParams).then((res) => {
        if(res.code == 200 && res.data) {
          self.list = self.list.concat(res.data.list)
          self.isLastPage = res.data.isLastPage
        }

        this.loading = false;
        this.tabelshow = true;
      })
    },

    // onListScroll(event){
    //   var ele = event.srcElement
    //   var scrollTop = ele.scrollTop,scrollHeight = ele.scrollHeight,elementHeight = parseInt(getComputedStyle(ele).height)
      
    //   if((scrollTop + elementHeight + 80) > scrollHeight) {
        
    //     if(!this.loading && !this.isLastPage) {
    //       this.listParams.pageNo += 1
    //       console.log(this.listParams.pageNo)
    //       this.queryData()
    //     }
    //   }
    // },

    handleScroll(){
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

      if (scrollTop === 0) {
        console.log('滚动到顶了');
      }

      if (scrollTop + clientHeight >= scrollHeight) {
        console.log('滚动到底了');

        
      }

      if((scrollTop + clientHeight + 80) > scrollHeight){
        console.log('加载下一页');
        if(!this.loading && !this.isLastPage) {
          this.listParams.pageNo += 1
          console.log(this.listParams.pageNo)
          this.queryData()
        }
      }
    }

   
  },
   mounted() {
    // window.addEventListener("")
    window.addEventListener('scroll', this.handleScroll) 
    
   }
}
</script>
<style lang="css" scoped>
.main {
  width: 846px;
}
.content {

  background: white;
}
.side {
  width: 314px;
}
.category-list {
  overflow-x: auto;
  
}
::-webkit-scrollbar {
  background-color: #fff;
  width: 1px;
  height: 1px;
}
.category-name {
  color: #666;
  font-size: 1.6rem;
  height: 60px;
  line-height: 60px;
  flex-shrink: 0;
  cursor: pointer;
}
.category-name.active {
  color: #ff4c61;
  font-weight: bold;
}
</style>
