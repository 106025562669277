<template>
  <div>
    <a target="_blank" href="https://help.shouqu.me/push/2018/0930website.html"><img style="width:100%" src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1665214494028986505.png"/></a> 
  </div>
</template>

<script>
export default {
  name:"UserRead"
}
</script>

<style>

</style>