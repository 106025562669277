<template>
    <div id="loading" style="width:100%;height:400px;">
      <div style="padding-top:150px">
            <lottie :options="defaultOptions" :height="50" :width="50" v-on:animCreated="handleAnimation"/>
      </div>
    </div>
</template>

<script>
  
  import Lottie from 'vue-lottie'
  import  animationData from './Loading.json';

  export default {
    name: 'loading',
    components: {
      'lottie': Lottie
    },
    data() {
    
      return {
        
        defaultOptions: {animationData: animationData},
        animationSpeed: 1,
        anim:""
      }
    },
    methods: {
      handleAnimation: function (anim) {
        this.anim = anim;
      },

      
    }
  }
</script>